import format from "date-fns/format";
import { arSA, enUS } from "date-fns/locale";
import { isEmpty, isNil, anyPass, when, pipe, take, append, join } from "ramda";

const truncate = when(
  str => str.length > 75,
  pipe(take(75), append("…"), join(""))
);

function localizedDate(unixStampedDate, format, isRTL) {
  const date = new Date(unixStampedDate);
  const options = {
    ...(format.includes("d") && { day: "numeric" }),
    ...(format.includes("m") && { month: "long" }),
    ...(format.includes("M") && { month: "2-digit" }),
    ...(format.includes("y") && { year: "numeric" }),
    ...(format.includes("w") && { weekday: "long" })
  };
  return isRTL
    ? date.toLocaleDateString("ar-SY", options)
    : date.toLocaleDateString("en-US", options);
}

function formatDate(date, locale = "en") {
  const isAr = locale === "ar";
  return format(new Date(date), "eee MMM dd yyyy", {
    locale: isAr ? arSA : enUS
  });
}

const breakpoints = [576, 768, 992, 1280, 1400];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const isNull = anyPass([isEmpty, isNil]);

export { localizedDate, mq, formatDate, isNull, truncate };
